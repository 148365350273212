// 89349
@import '../../../helpers/componentHeader';
@import '../../../components/buttons';
// 107528
.banner-image-squareshadow {
    .banner-component-container {
        @include media-breakpoint-down(md) {
            padding-left: 0px;
            padding-right:0px;
        }
        .banner-section {
            background-color: $primary-darkest;
            padding: 24px;
            @include media-breakpoint-down(md) {
                padding: 30px 24px;
            }
            &__shadow-image {
                margin: 0px;
                @include media-breakpoint-up(md) {
                    padding-right: 15px;
                }
                .photo-tile-image {
                    width: 100%;
                    object-fit: cover;
                    box-shadow: 18px 18px rgba($white, .09);
                    margin-bottom: 18px;
                    @include media-breakpoint-up(xl){
                        width: 488px;
                    }
                    @include media-breakpoint-down(lg){
                        max-height: 400px;
                    }
                    @include media-breakpoint-down(sm) {
                        max-height: 350px;
                        box-shadow: 12px 12px rgba($white, .09);
                    }
                }
            }
            &__row {
                display: flex;
                flex-flow: column;
                justify-content: center;
                height: 100%;
            }
            &__title {
                font-family: $primary-font;
                font-size: 23px;
                line-height: 41px;
                color: $white;
                border-left: 4px solid $yellow;
                padding-left: 20px;
                @include media-breakpoint-down(lg) {
                    line-height: 36px;
                }
                @include media-breakpoint-down(md) {
                    margin-top: 30px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 18px;
                    line-height: 32px;
                }
            }
            &__description {
                display: flex;
                font-family: $primary-font;
                font-size: 16px;
                line-height: 32px;
                color: $zircon;
                margin: 0px;
                padding: 16px 0px 16px 24px;
                @include media-breakpoint-down(lg) {
                    padding: 8px 20px 8px 24px;
                    line-height: 28px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 22px;
                }
            }
            &__button-area {
                padding: 20px 24px 0px;
                text-transform: uppercase;
            }
        }
    }
}